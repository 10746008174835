import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { wrapper } from "./Testimonial.module.css"

const Testimonial = () => {
  return (
    <section id="order" className="section gray">
      <div className="container">
        <h2 className="is-size-1">See what our customers say</h2>
        <div className={wrapper}>
          <div>
            <StaticImage src="../images/testimonial1.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/testimonial2.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/testimonial3.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/testimonial4.png" alt="Amazon Ungating Services Testimonial" />
          </div>
        </div>
        <h2 className="is-size-1">Over 1000 successful cases</h2>
        <div className={wrapper}>
          <div>
            <StaticImage src="../images/dashboard1.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/dashboard2.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/lighting.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/medical.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/otc.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/personal.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/powertools.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/supplements.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/topicals.png" alt="Amazon Ungating Services Testimonial" />
          </div>
          <div>
            <StaticImage src="../images/toys.png" alt="Amazon Ungating Services Testimonial" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial