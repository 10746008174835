import { Link } from "gatsby"
import React from "react"

const OtherService = () => {
  return (
    <section id="otherservice" className="section light">
      <div className="container">
        <h2 className="is-size-1">Reactivate your suspended account</h2>
        <div>We know how frustrating it is to have your account deactivated by Amazon. Let us help reactivate your suspended account.</div>
        <br/>
        <div>Send email to hello@amazonungating.services and talk to our specialists now.</div>
        <Link to="/service/amazon-reactivate-account"><button className="cta">Learn More</button></Link>
      </div>
    </section>
  )
}

export default OtherService