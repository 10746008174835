import React from "react"

import StoreLayout from "../components/StoreLayout"
import Hero from "../components/Hero"
import HowItWorks from "../components/HowItWorks"
import Order from "../components/Order"
import OtherService from "../components/OtherService"
import Faq from "../components/Faq"
import Testimonial from "../components/Testimonial"
import SEO from "../components/SEO"

const IndexPage = () => (
  <StoreLayout>
    <SEO title="Amazon Category Approval Service" keywords={[`Amazon`, `ungating`, `category`, `approval`, `reactivate`, `reinstate`, `suspended`, `account`]} />
    <Hero></Hero>
    <HowItWorks></HowItWorks>
    <Order></Order>
    <OtherService></OtherService>
    <Faq></Faq>
    <Testimonial></Testimonial>
  </StoreLayout>
)

export default IndexPage
