import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { grid, badgeWrapper, badge } from "./HowItWorks.module.css"

const HowItWorks = () => {
  return (
    <section className="container section">
      <div className={grid}>
        <div>
          <h3 className="is-size-1">How it works</h3>
          <p>1. <AnchorLink to="/#order">Order the Amazon restricted categories</AnchorLink> you want to ungate.</p>
          <p>2. We will ask you to provide some information and a user account with limited access rights for us to work with.</p>
          <p>3. Sit back and relax. Normally it takes 24 hours to 48 hours to get approved. In some rare cases it may take up to 2 weeks.</p>
          <p>Before you order, please make sure that:</p>
          <ul>
            <li>you have an Amazon Professional Seller Account for the marketplace you need; and</li>
            <li>your account health meets <a href="https://sellercentral.amazon.com/gp/help/help-page.html?itemID=200205250">the policy stated by Amazon</a> (Cancellation Rate under 2.5%, Order Defect Rate under 1%, and Late Shipment Rate under 4%)</li>
          </ul>
          <p>Having questions? You will probably find it in the <AnchorLink to="/#faq">FAQs.</AnchorLink></p>
        </div>
        <div>
          <h3 className="is-size-1">100% money-back guarantee</h3>
          <p>If we cannot get you approved by Amazon for the restricted categories you want, we will give you back 100% of your money<sup>*</sup>. It's totally risk-free.</p>
          <small><sup>*</sup><i>Please refer to the <Link to="/pages/refund-policy">Refund Policy</Link> for details</i></small>
          <div className={badgeWrapper}><StaticImage src="../images/money-back-badge.png" alt="100% money-back guarantee" className={badge}></StaticImage></div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks