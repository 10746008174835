import React from "react"
import Products from "./Products"

const Order = () => {
  return (
    <section id="order" className="section primary">
      <div className="container">
        <h2 className="is-size-1">Select the categories to ungate</h2>
        <Products></Products>
        <br/>
        <div>* We also help sellers ungate other marketplaces (e.g. UK, Germany, Japan).</div>
        <div>Please send email to hello@amazonungating.services if you can't find the services you need from the list above.</div>
      </div>
    </section>
  )
}

export default Order