import React from "react"
import Collapsible from 'react-collapsible'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Faq = () => {
  return (
    <section id="faq" className="container section">
      <h3>FAQs</h3>
      <Collapsible
        trigger="＋ &nbsp;Can you get brand approval as well?"
        triggerWhenOpen="－ &nbsp;Can you get brand approval as well?"
        transitionTime={10}
      >
        <p>Yes. Please send us an email and let me know which brand you want to ungate.</p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Do I have to get approval again for every product I sell in the restricted category?"
        triggerWhenOpen="－ &nbsp;Do I have to get approval again for every product I sell in the restricted category?"
        transitionTime={10}
      >
        <p>No. You only need to get approval once in the restricted category you want to sell.</p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Do I need to prepare the documents and communicate with Amazon myself?"
        triggerWhenOpen="－ &nbsp;Do I need to prepare the documents and communicate with Amazon myself?"
        transitionTime={10}
      >
        <p>No. We will do all the work for you.</p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Can you get approval for brand new Amazon account?"
        triggerWhenOpen="－ &nbsp;Can you get approval for brand new Amazon account?"
        transitionTime={10}
      >
        <p>Yes. Even brand new account can get approvals to sell in restricted categories.</p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Can you ungate if my approval request to sell in restricted categories have been rejected by Amazon before?"
        triggerWhenOpen="－ &nbsp;Can you help if my approval request to sell in restricted categories have been rejected by Amazon before?"
        transitionTime={10}
      >
        <p>Yes. We can help you get approvals to sell in restricted categories even if you have been rejected before.</p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Can you ungate if I don't have the required documents?"
        triggerWhenOpen="－ &nbsp;Can you ungate if I don't have the required documents?"
        transitionTime={10}
      >
        <p>Yes. We will provide all the required documents for ungating the restricted categories you need.</p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;What are the requirements for getting approval to sell in restricted categories?"
        triggerWhenOpen="－ &nbsp;What are the requirements for getting approval to sell in restricted categories?"
        transitionTime={10}
      >
        <p>You need a professional seller account with account health meeting <a href="https://sellercentral.amazon.com/gp/help/help-page.html?itemID=200205250">the policy stated by Amazon</a></p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Which Amazon marketplaces and restricted categories can you ungate?"
        triggerWhenOpen="－ &nbsp;Which Amazon marketplaces and restricted categories can you ungate?"
        transitionTime={10}
      >
        <p>We can ungate any restricted categories for any Amazon marketplaces. However, ungating service may not be available for some marketplaces from time to time. Please send us an email if it is not in the <AnchorLink to="/#order">service list.</AnchorLink></p>
      </Collapsible>
      <Collapsible
        trigger="＋ &nbsp;Do you offer any other Amazon service?"
        triggerWhenOpen="－ &nbsp;Do you offer any other Amazon service?"
        transitionTime={10}
      >
        <p>We offer Amazon suspended account reinstatement service, Amazon brand gating, trademark registration and other services as well. Please feel free to reach out if you want to reactivate a deactivated account or have any other needs.</p>
      </Collapsible>
    </section>
  )
}

export default Faq