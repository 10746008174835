import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { cta, contactcta, numbersWrapper, flex, large, text } from "./Hero.module.css"

const Hero = () => {
  return (
    <section className="hero section">
      <div className="container section">
        <h1 className="title">Ungating Amazon restricted categories.<br/>Get approval to sell now.</h1>
        <div className="subtitle">Even brand new sellers can ungate Amazon restricted categories. <br/> 100% money-back guarantee. <br/> Totally safe and hassle free.</div>
        <AnchorLink to="/#order"><button className={cta}>Ungate Restricted Categories</button></AnchorLink>
        <AnchorLink to="/#contact"><button className={contactcta}>Contact Us</button></AnchorLink>
        <div className={numbersWrapper}>
          <div className={flex}><span className={large}>1000+</span> <span className={text}>Happy<br/>Customers</span></div>
          <div className={flex}><span className={large}>100%</span> <span className={text}>Successful<br/>Rate</span></div>
        </div>

      </div>
    </section>
  )
}

export default Hero