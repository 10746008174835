import React, { useContext } from "react"
import PropTypes from "prop-types"

import { CartContext } from "./CartProvider"
import { description, atc } from "./ProductThumbnail.module.css"

const ProductThumbnail = ({ product }) => {
  const { add, toggle, available } = useContext(CartContext)

  const availablePrices = product.prices.filter(({ id }) => available(id))
  const soldOut = availablePrices.length === 0

  const tracking = (product) => {
    const payload = {
      'event': 'add_to_cart',
      'ecommerce': {
        'items': [{
          'item_name': product.name, // Name or ID is required.
          'item_id': product.id,
          'price': product.prices[0].unit_amount / 100,
          'currency': product.prices[0].currency,
          'quantity': 1
        }]
      }
    }

    if (process.env.NODE_ENV === "development") {
      console.log("Tracking: Add to cart")
      console.log(payload)
    } else {
      dataLayer.push(payload)
    }
  }

  return (
    <div key={product.id} className={description}>
      <button
        className={atc}
        onClick={() => {
          add(product.prices[0].id)
          tracking(product)
          toggle(true)
        }}
        disabled={!available(product.prices[0].id)}
        data-atc-content-name={product.name}
        data-atc-currency="USD"
        data-atc-value={product.prices[0].unit_amount / 100}
      >
        {available(product.prices[0].id) ? "Add To Cart" : "Sold Out"}
      </button>
      <div>{product.name}: {soldOut ? "Sold Out" : <span>${product.prices[0].unit_amount / 100}</span>}</div>
    </div>
  )
}

ProductThumbnail.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductThumbnail
